export function pluralize(number: number, titles: string[]) {
    const decl1 = 1;
    const decl2x4 = 4;
    const titleOther = 2;
    const ten = 10;

    if (number % (ten * ten) >= ten && number % (ten * ten) < ten + ten) return titles[titleOther];
    if (number % ten === decl1) return titles[0];
    if (number % ten > decl1 && number % ten <= decl2x4) return titles[1];
    return titles[titleOther];
}
