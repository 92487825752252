export type TReward = {
    icon: string | null;
    position: string;
    rewardUSDT: string;
    rewardSWITCH: string;
};

export const rewards: TReward[] = [
    {
        icon: '/inviteContest/first-place.png',
        position: '1',
        rewardUSDT: '5 000',
        rewardSWITCH: '500 000',
    },
    {
        icon: '/inviteContest/second-place.png',
        position: '2',
        rewardUSDT: '1 000',
        rewardSWITCH: '100 000',
    },
    {
        icon: '/inviteContest/third-place.png',
        position: '3',
        rewardUSDT: '500',
        rewardSWITCH: '50 000',
    },
    {
        icon: null,
        position: '4',
        rewardUSDT: '250',
        rewardSWITCH: '25 000',
    },
    {
        icon: null,
        position: '5',
        rewardUSDT: '225',
        rewardSWITCH: '22 500',
    },
    {
        icon: null,
        position: '6',
        rewardUSDT: '200',
        rewardSWITCH: '20 000',
    },
    {
        icon: null,
        position: '7',
        rewardUSDT: '175',
        rewardSWITCH: '17 500',
    },
    {
        icon: null,
        position: '8',
        rewardUSDT: '150',
        rewardSWITCH: '15 000',
    },
    {
        icon: null,
        position: '9',
        rewardUSDT: '125',
        rewardSWITCH: '12 500',
    },
    {
        icon: null,
        position: '10-19',
        rewardUSDT: '100',
        rewardSWITCH: '10 000',
    },
    {
        icon: null,
        position: '20-39',
        rewardUSDT: '75',
        rewardSWITCH: '7 500',
    },
    {
        icon: null,
        position: '40-69',
        rewardUSDT: '50',
        rewardSWITCH: '5 000',
    },
    {
        icon: null,
        position: '70-100',
        rewardUSDT: '30',
        rewardSWITCH: '3 000',
    },
    {
        icon: null,
        position: '101-∞',
        rewardUSDT: '25',
        rewardSWITCH: '2 500',
    },
];

export const getRewardForPosition = (position: number) => {
    return rewards.find((reward) => {
        if (reward.position.includes('-')) {
            const [start, end] = reward.position.split('-').map((n) => parseInt(n));
            if (!end || isNaN(end)) {
                return position >= start;
            }
            return position >= start && position <= end;
        }
        return parseInt(reward.position) === position;
    });
};

export const getRandomAvatarForContest = (user: string) => {
    const avatarId = (user.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % 5) + 1;

    return `/inviteContest/user-${avatarId}.png`;
};
