import { getRewardForPosition } from 'inviteContest/utils.ts';
import { useTranslation } from 'i18n';

type Props = {
    rank: number;
};
export const EstimatedPrize = ({ rank }: Props) => {
    const { t } = useTranslation();
    const prize = getRewardForPosition(rank);

    if (!prize) {
        return null;
    }

    return (
        <div className="font-Wix flex flex-col gap-0.5">
            <span className="text-2xs font-semibold text-invite-contest-dark-blue">
                {t('invite-contest.estimated-prize')}
            </span>
            <div className="flex items-center text-xs gap-2">
                <div className="flex gap-1 items-center">
                    <img src="/tether.png" alt="" className="w-3 h-3 -mt-0.5" />
                    <span>{prize.rewardUSDT}</span>
                </div>
                <div className="flex gap-1 items-center">
                    <img src="/switch.png" alt="" className="w-3 h-3 -mt-0.5" />
                    <span>{prize.rewardSWITCH}</span>
                </div>
            </div>
        </div>
    );
};
