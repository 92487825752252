import { ICCard } from 'inviteContest/components/icCard.tsx';
import { EstimatedPrize } from 'inviteContest/components/estimatedPrize.tsx';
import { RatingUpBadge } from 'inviteContest/components/ratingUpBadge.tsx';
import { useTranslation } from 'i18n';

type Props = {
    username: string;
    rating?: number;
    prevRating?: number;
    points?: number;
};

export const ContestUserWidget = ({ username, rating, prevRating, points }: Props) => {
    const { t } = useTranslation();
    return (
        <ICCard className="mt-5 px-4 py-3 w-full flex items-center justify-between">
            <div className="flex items-center gap-3">
                <div
                    className="rounded-xl shadow-inner"
                    style={{
                        boxShadow: '0px -1px 2px 0px #00000099 inset',
                    }}
                >
                    <img
                        src={'/inviteContest/punk-dog.png'}
                        alt="punkdog"
                        className="w-12 h-12 rounded-xl shadow-[0_-1px_4px_0_#FFFFFF40_inset]"
                    />
                </div>
                <div>
                    <span>{username}</span>
                    {rating && <EstimatedPrize rank={rating} />}
                </div>
            </div>
            <div className="flex flex-col items-end gap-1.5">
                <div className="flex items-center gap-1">
                    <img src="/inviteContest/happy-star.gif" alt="star" className="w-4" />
                    <span className="text-2xs">{t('invite-contest.rating')}</span>
                </div>
                <div className="flex items-center gap-1.5">
                    <span className="font-bold">#{rating ?? 'N/A'}</span>
                    {rating && prevRating && <RatingUpBadge diff={prevRating - rating} />}
                </div>
                {points && (
                    <span className="text-xs text-invite-contest-dark-blue">
                        {Intl.NumberFormat('ru', {
                            maximumFractionDigits: 2,
                        })
                            .format(points)
                            .replace(',', '.')}
                    </span>
                )}
            </div>
        </ICCard>
    );
};
