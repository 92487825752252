import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import { Skeleton } from 'common/components/skeleton.tsx';

type Props = {
    title: string;
    value: number | null;
    prevValue: number | null;
    icon?: string;
};

export const DisplayScoreCriteria = ({ title, value, prevValue, icon }: Props) => {
    const isFetching = value === null;

    const scoreDiff =
        value && prevValue !== null ? parseFloat(normalizedToFixed(value - prevValue, 2)) : null;

    return (
        <div className="flex-1 flex flex-col justify-between items-start gap-1 h-full">
            <div className="flex items-center gap-2">
                <span className="text-xs text-invite-contest-dark-blue">{title}</span>
            </div>
            <div className="flex items-center gap-1">
                {!isFetching ? (
                    <span className="text-md font-bold font-Wix">
                        {normalizedToFixed(value, 2)}
                    </span>
                ) : (
                    <Skeleton size="sm" className="w-10" />
                )}
                {icon && <img src={icon} alt="" className="w-4 h-4" />}
                {!!scoreDiff && (
                    <span className="text-xs text-[#5B8F28] font-semibold">{`+${scoreDiff}`}</span>
                )}
            </div>
        </div>
    );
};
