import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { TimerUntilEnd } from 'inviteContest/components/timerUntilEnd.tsx';
import { TotalPrizeFund } from 'inviteContest/components/totalPrizeFund.tsx';
import { ICCard } from 'inviteContest/components/icCard.tsx';
import { useTranslation } from 'i18n';
import { SWITCH_REWARD_AMOUNT, USDT_REWARD_AMOUNT } from 'inviteContest/constants.ts';

export const HowToContestPage = () => {
    const { t } = useTranslation();
    useBackButton({
        callback: navigation.joinContest,
        from: '/inviteContest/how-to',
    });

    return (
        <div className="bg-invite-contest-primary h-screen flex flex-col items-center gap-5 py-3 px-4 pb-6 font-Wix overflow-y-auto">
            <div className="flex items-center justify-between gap-2 w-full">
                <TimerUntilEnd className="!bg-invite-contest-primary border border-invite-contest-background/10 shadow-none" />
                <TotalPrizeFund className="!bg-invite-contest-primary border border-invite-contest-background/10 shadow-none" />
            </div>
            <ICCard className="p-5 w-full flex flex-col items-center">
                <div className="flex items-center justify-center gap-3 mb-5">
                    <img src="/inviteContest/goku.gif" alt="" className="h-8 rounded-lg" />
                    <h1 className="text-xl font-bold">{t('invite-contest.how-to-start')}</h1>
                </div>
                <div className="flex flex-col w-full pb-5 border-b border-dark/10 mb-5">
                    <h2 className="font-semibold text-md">
                        {t('invite-contest.how-to-start.earn-points')}
                    </h2>
                    <ul className="list-disc pl-6 text-sm leading-4">
                        {[
                            t('invite-contest.how-to-start.earn-points.trade'),
                            t('invite-contest.how-to-start.earn-points.invite'),
                            t('invite-contest.how-to-start.earn-points.earn'),
                            t('invite-contest.how-to-start.earn-points.example'),
                        ].map((text) => (
                            <li
                                key={text}
                                className="border-b border-dark/10 py-3 last:border-0"
                                dangerouslySetInnerHTML={{
                                    __html: text.replace(
                                        /<>(.*?)<\/>/gi,
                                        '<span class="font-bold">$1</span>'
                                    ),
                                }}
                            />
                        ))}
                    </ul>
                    <p className="text-sm leading-4">
                        <sup>*</sup>
                        {t('invite-contest.how-to-start.earn-points.update-time')}
                    </p>
                </div>
                <div className="flex flex-col gap-2 pb-5 border-b border-dark/10 mb-5">
                    <h2 className="font-semibold text-md">
                        {t('invite-contest.how-to-start.rewards')}
                    </h2>
                    <p
                        className="text-sm"
                        dangerouslySetInnerHTML={{
                            __html: t('invite-contest.how-to-start.rewards.description')
                                .replace(
                                    '{usdtAmount}',
                                    Intl.NumberFormat('ru').format(USDT_REWARD_AMOUNT)
                                )
                                .replace(
                                    '{switchAmount}',
                                    Intl.NumberFormat('ru').format(SWITCH_REWARD_AMOUNT)
                                )
                                .replace(/<>(.*?)<\/>/gi, '<span class="font-bold">$1</span>'),
                        }}
                    />
                </div>
                <div className="flex flex-col w-full">
                    <h2 className="font-semibold text-md">
                        {t('invite-contest.how-to-start.additional-bonuses')}
                    </h2>
                    <p className="text-sm">
                        {t('invite-contest.how-to-start.additional-bonuses.description')}:
                    </p>
                    <ul className="list-disc pl-6 text-sm">
                        {[
                            t('invite-contest.how-to-start.additional-bonuses.1'),
                            t('invite-contest.how-to-start.additional-bonuses.2'),
                        ].map((text) => (
                            <li
                                key={text}
                                className="border-b border-dark/10 py-3 last:border-0"
                                dangerouslySetInnerHTML={{
                                    __html: text.replace(
                                        /<>(.*?)<\/>/gi,
                                        '<span class="font-bold">$1</span>'
                                    ),
                                }}
                            />
                        ))}
                    </ul>
                </div>
            </ICCard>
            <button className="flex-1" onClick={navigation.contestRewards}>
                <ICCard className="flex justify-center items-center gap-1.5 p-3 rounded-xl">
                    <img
                        src="/inviteContest/cup.gif"
                        alt=""
                        className="h-6 aspect-square object-cover rounded-lg"
                    />
                    <span>{t('invite-contest.rewards')}</span>
                </ICCard>
            </button>
        </div>
    );
};
