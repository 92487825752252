import WebApp from '@twa-dev/sdk';
import { useCallback, useEffect } from 'react';

type useTelegramButtonProps = {
    text: string;
    color?: string;
    text_color?: string;
    onClick?: () => void;
    disable?: boolean;
    load?: boolean;
    useDefaultSettings?: boolean;
};

// usage as hook in case we'll need to mix with store
export const useMainButton = ({
    text,
    color,
    text_color,
    onClick,
    disable,
    load,
    useDefaultSettings = true,
}: useTelegramButtonProps) => {
    const show = () => {
        WebApp.MainButton.show();
    };

    const hide = () => {
        WebApp.MainButton.offClick(handleClick);
        WebApp.MainButton.hide();
    };

    const setText = (text: string) => {
        WebApp.MainButton.setText(text);
    };

    const setColor = (color: string, text_color: string) => {
        WebApp.MainButton.setParams({
            color,
            text_color,
        });
    };

    const handleClick = useCallback(() => {
        if (!load && !disable && onClick) {
            onClick();
        }
    }, [load, disable, onClick]);

    useEffect(() => {
        WebApp.MainButton.onClick(handleClick);

        return () => {
            WebApp.MainButton.offClick(handleClick);
        };
    }, [disable, load, onClick]);

    useEffect(() => {
        if (!useDefaultSettings) return;

        setText(text || 'Confirm');
        WebApp.MainButton.setParams({
            color: load || disable ? '#C0C0C6' : color || '#2c2c2c',
            text_color: load || disable ? '#8C8C91' : text_color || '#ffffff',
        });
        if (load) {
            WebApp.MainButton.showProgress(true);
        } else {
            WebApp.MainButton.hideProgress();
        }
    }, [text, color, onClick, disable, load]);

    useEffect(() => {
        return () => {
            hide();
            WebApp.MainButton.offClick(handleClick);
        };
    }, []);

    return {
        setText,
        setColor,
        show,
        hide,
    };
};
