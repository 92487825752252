import { FC, HTMLAttributes, PropsWithChildren } from 'react';

export const ICButton: FC<PropsWithChildren<HTMLAttributes<HTMLButtonElement>>> = ({
    children,
    className,
    ...props
}) => {
    return (
        <button className={`font-Wix rounded-xl py-3.5 leading-none ${className}`} {...props}>
            {children}
        </button>
    );
};
