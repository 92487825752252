import { ICCard } from 'inviteContest/components/icCard.tsx';
import { FC } from 'react';
import { useTranslation } from 'i18n';
import { SWITCH_REWARD_AMOUNT, USDT_REWARD_AMOUNT } from 'inviteContest/constants.ts';

type TimerUntilEndProps = {
    className?: string;
};

export const TotalPrizeFund: FC<TimerUntilEndProps> = ({ className }) => {
    const { t } = useTranslation();
    return (
        <ICCard className={`px-3 py-1.5 ${className}`}>
            <p className="text-2xs text-invite-contest-dark-blue">
                {t('invite-contest.total-prize-fund')}:
            </p>
            <div className="flex items-center gap-1.5">
                <img src="/tether.png" alt="" className="w-4 h-4 rounded-full" />
                <span className="text-sm leading-none">${USDT_REWARD_AMOUNT}</span>

                <img src="/switch.png" alt="" className="w-4 h-4 rounded-full" />
                <span className="text-sm leading-none">{SWITCH_REWARD_AMOUNT}</span>
            </div>
        </ICCard>
    );
};
