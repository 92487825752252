import { TimerUntilEnd } from 'inviteContest/components/timerUntilEnd.tsx';
import { TotalPrizeFund } from 'inviteContest/components/totalPrizeFund.tsx';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { ICCard } from 'inviteContest/components/icCard.tsx';
import { rewards, TReward } from 'inviteContest/utils.ts';
import { useTranslation } from 'i18n';

export const ContestRewardsPage = () => {
    const { t } = useTranslation();
    useBackButton({
        callback: navigation.joinContest,
        from: '/inviteContest/rewards',
    });

    const hundredRandomRewards = rewards.at(-1);

    return (
        <div className="bg-invite-contest-primary h-screen flex flex-col items-center gap-5 py-3 px-4 pb-6 font-Wix overflow-y-auto">
            <div className="flex items-center justify-between gap-2 w-full">
                <TimerUntilEnd className="!bg-invite-contest-primary border border-invite-contest-background/10 shadow-none" />
                <TotalPrizeFund className="!bg-invite-contest-primary border border-invite-contest-background/10 shadow-none" />
            </div>
            <ICCard className="p-5 pb-2 w-full flex flex-col items-center gap-5">
                <div className="flex items-center justify-center gap-3">
                    <img
                        src="/inviteContest/cup.gif"
                        alt=""
                        className="w-8 aspect-square rounded-lg"
                    />
                    <h1 className="text-xl font-bold">{t('invite-contest.rewards-table')}</h1>
                </div>
                <div className="w-full">
                    <div className="flex items-center justify-between w-full text-xs text-invite-contest-dark-blue">
                        <span>{t('invite-contest.position').toLowerCase()}</span>
                        <span>{t('invite-contest.reward').toLowerCase()}</span>
                    </div>
                    {rewards.slice(0, -1).map((reward) => (
                        <Reward key={reward.position} reward={reward} />
                    ))}
                </div>
            </ICCard>
            <div className="w-full flex justify-center items-center gap-2.5 py-1">
                <img src="/inviteContest/toung.gif" alt="" className="w-12" />
                <span>{t('invite-contest.thats-not-all').toLowerCase()}</span>
            </div>
            <ICCard className="p-5 pb-2 w-full flex flex-col items-center">
                <h2 className="text-xl font-bold mb-2">
                    {t('invite-contest.you-still-have-a-chance')}
                </h2>
                <p
                    className="text-sm text-center mb-3"
                    dangerouslySetInnerHTML={{
                        __html: t('invite-contest.you-still-have-a-chance.description').replace(
                            /<>(.*?)<\/>/gi,
                            '<span class="font-bold">$1</span>'
                        ),
                    }}
                ></p>
                <div className="w-full">
                    <div className="flex items-center justify-between w-full text-xs text-invite-contest-dark-blue">
                        <span>{t('invite-contest.position').toLowerCase()}</span>
                        <span>{t('invite-contest.reward').toLowerCase()}</span>
                    </div>
                    <div className="flex items-center justify-between w-full gap-3 py-2.5 border-b border-dark/10 last:border-b-0">
                        <div className="flex items-center gap-1">
                            <span className="italic relative">
                                #101-
                                <span className="text-xl font-thin leading-none absolute">∞</span>
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <img src="/tether.png" alt="" className="w-4 h-4" />
                            <span className="mr-2">{hundredRandomRewards?.rewardUSDT}</span>
                            <img src="/switch.png" alt="" className="w-4 h-4" />
                            <span>{hundredRandomRewards?.rewardSWITCH}</span>
                        </div>
                    </div>
                </div>
            </ICCard>
            <button onClick={navigation.contestHowTo}>
                <ICCard className="flex justify-center items-center gap-1.5 p-3 rounded-xl">
                    <img
                        src="/inviteContest/goku.gif"
                        alt=""
                        className="h-6 aspect-square object-cover rounded-lg"
                    />
                    <span>{t('invite-contest.how-to-start')}</span>
                </ICCard>
            </button>
        </div>
    );
};

const Reward = ({ reward, positionClassName }: { reward: TReward; positionClassName?: string }) => (
    <div className="flex items-center justify-between w-full gap-3 py-2.5 border-b border-dark/10 last:border-b-0">
        <div className="flex items-center gap-1">
            {reward.icon ? (
                <img src={reward.icon} alt="" className="w-4 aspect-square rounded-lg" />
            ) : (
                <div className="w-4 aspect-square" />
            )}
            <span className={positionClassName}>#{reward.position}</span>
        </div>
        <div className="flex items-center gap-1">
            <img src="/tether.png" alt="" className="w-4 h-4" />
            <span className="mr-2">{reward.rewardUSDT}</span>
            <img src="/switch.png" alt="" className="w-4 h-4" />
            <span>{reward.rewardSWITCH}</span>
        </div>
    </div>
);
