import { notificationSuccess } from './tgUtilt.ts';
import { router } from 'common/utils/router.tsx';

export const basicNavigate = (path: string) => {
    router.navigate(path);
    notificationSuccess();
};

const navigateWithParams = (path: string) => {
    return () => basicNavigate(path);
};

export const navigation = {
    mainPage: navigateWithParams('/'),
    createToken: navigateWithParams('/create'),
    tokenTrade: (tokenId: string) => navigateWithParams(`/token/${tokenId}/trade`),
    tokenAbout: (tokenId: string, hash?: string) =>
        navigateWithParams(`/token/${tokenId}/about${hash ? `#${hash}` : ''}`),
    frens: navigateWithParams('/frens'),
    cabalArena: navigateWithParams('/cabal-arena'),
    inviteContest: navigateWithParams('/invite-contest'),
    joinContest: navigateWithParams('/invite-contest/join'),
    contestRewards: navigateWithParams('/invite-contest/rewards'),
    contestHowTo: navigateWithParams('/invite-contest/how-to'),
    contestLeaderboard: navigateWithParams('/invite-contest/leaderboard'),
};
