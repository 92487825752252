import { useEffect, useState } from 'react';

type Props = {
    timestamp: number;
    direction?: 'since' | 'left';
    interval?: number;
};

export const useTimer = ({ timestamp, direction = 'left', interval = 1000 }: Props) => {
    const calculateTime = () => {
        if (!timestamp) return 0;
        const now = Date.now();
        const date = new Date(timestamp).getTime();
        if (direction === 'since') {
            const diff = now - date;
            return diff < 0 ? 0 : diff;
        }
        const diff = date - now;
        return diff < 0 ? 0 : diff;
    };

    const [time, setTime] = useState(calculateTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(calculateTime());
        }, interval);

        return () => clearInterval(intervalId);
    }, [timestamp]);

    return time;
};
