import { Coin } from 'coin/coin.store.ts';
import { tonProofService } from 'tonkeeper/tonproof.service.ts';
import { releaseStage } from 'common/constants';

const mainServerUrl = import.meta.env.VITE_SERVER_URL;
const additionalServerUrls = import.meta.env.VITE_SERVER_ADDITIONAL_URLS;

const serverList = [mainServerUrl];
if (additionalServerUrls) {
    serverList.push(...additionalServerUrls.split(' '));
}

const mainPocketFiServerUrl = import.meta.env.VITE_POCKETFI_SERVER_URL;

const pocketFiServerList = [mainPocketFiServerUrl];

export class FetchController {
    abortSignalsMap: Record<string, AbortController> = {};
    retryQueue: Record<string, RequestInit> = {};

    serverUrl: string;

    constructor(
        private readonly serverList: string[],
        private readonly localStoragePrefix: string = ''
    ) {
        console.log('test log', { serverList });
        if (serverList.length < 1) {
            throw new Error('FetchController: empty server list');
        }
        this.serverUrl = localStorage.getItem(this.localStorageServerUrlKey) || serverList[0];
    }

    private get localStorageServerUrlKey(): string {
        const key = 'serverUrl';
        if (this.localStoragePrefix !== '') {
            return `${this.localStoragePrefix}.${key}`;
        }
        return key;
    }

    changeServer(currentUrl: string) {
        if (this.serverList.length === 1) {
            return currentUrl;
        }
        const currentIndex = this.serverList.indexOf(currentUrl);
        const newUrl = this.serverList[(currentIndex + 1) % this.serverList.length];
        this.serverUrl = newUrl;
        localStorage.setItem(this.localStorageServerUrlKey, newUrl);
        return newUrl;
    }

    registerFetch(url: string) {
        if (this.abortSignalsMap[url]) {
            this.abortSignalsMap[url].abort();
        }
        const abortController = new AbortController();
        this.abortSignalsMap[url] = abortController;
        return abortController;
    }

    clearFetch(url: string) {
        if (this.abortSignalsMap[url]) {
            this.abortSignalsMap[url].abort();
            delete this.abortSignalsMap[url];
        }
    }
}

export const authorizedFetcher =
    (controller: FetchController) =>
    async (url: string, options: RequestInit = {}) => {
        // @ts-expect-error
        const initData = window?.Telegram?.WebApp?.initData as string;
        const bearerToken = tonProofService.accessToken;

        if (!initData && !bearerToken) {
            return {
                ok: false,
                json: () => {},
            };
        }

        const authOptions = {
            ...(initData ? { telegramRawData: initData } : {}),
            ...(bearerToken
                ? {
                      Authorization: `Bearer ${bearerToken}`,
                  }
                : {}),
            ...(releaseStage === 'development'
                ? {
                      'x-check-token': initData ?? bearerToken,
                  }
                : {}),
        };

        const headers = options.headers || {};

        const start = Date.now();
        const abortController = controller.registerFetch(url);
        const timeout = setTimeout(() => {
            controller.clearFetch(url);
            console.log('Request aborted due to timeout');
        }, 10000);
        const requestOptions = {
            ...options,
            headers: {
                ...headers,
                ...authOptions,
            },
            signal: abortController.signal,
        };
        const currentServerUrl = controller.serverUrl;
        try {
            const response = await fetch(`${currentServerUrl}${url}`, requestOptions);
            clearTimeout(timeout);
            const requestDuration = Date.now() - start;
            if (requestDuration > 5000) {
                controller.changeServer(currentServerUrl);
            }
            return response;
        } catch (e) {
            clearTimeout(timeout);
            const newUrl = controller.changeServer(currentServerUrl);
            return await fetch(`${newUrl}${url}`, requestOptions);
        }
    };

export const authorizedFetch = authorizedFetcher(new FetchController(serverList));
export const pocketFiAuthorizedFetch = authorizedFetcher(new FetchController(pocketFiServerList));

export const routes = {
    tonConnectConnect: '/api/v1/ton_connect/connect',

    me: '/api/v1/users/me',
    whitelistTotal: '/api/v1/users/whitelist_total',

    coins: '/api/v1/coins',
    coinsSearch: '/api/v1/coins/search',
    coinsKingOfHill: '/api/v1/coins/king_hill',
    coinsCreateCoin: '/api/v1/coins/create',
    coinById: '/api/v1/coins',
    coinByIdTransaction: (coinId: Coin['id']) => `/api/v1/coins/${coinId}/transactions`,
    coinByIdHolders: (coinId: Coin['id']) => `/api/v1/coins/${coinId}/holders`,
    coinByIdStats: (coinId: Coin['id']) => `/api/v1/coins/${coinId}/stats`,
    coinByAddress: (address: string) => `/api/v1/coins/address/${address}`,

    coinChart: '/api/v1/transactions/klines',
    coinChartRange: '/api/v1/transactions/klines/range',
    activity: '/api/v1/transactions/activity',

    communityNotes: '/api/v1/community_notes',
    communityNotesUpdateReaction: '/api/v1/community_notes/updateReaction',

    referralsRefer: '/api/v1/referrals/refer',
    referralsStats: '/api/v1/referrals/stats',
    ownerCoinStats: '/api/v1/referrals/ownerCoinStats',
    referralJoinWaitlist: '/api/v1/referrals/join_waitlist',
    referralDirectFrensCount: '/api/v1/referrals/direct_friends_count',

    changeAnonSetting: '/api/v1/users/anon_setting',

    tonPrice: '/api/v1/transactions/ton_price',

    contestLeaderboard: '/contest/leaderboard',
    contestMe: '/contest/me',
    contestInit: '/contest/init',
};
