import { ICCard } from 'inviteContest/components/icCard.tsx';
import { FC } from 'react';
import { useTranslation } from 'i18n';
import { useInviteContestTimeLeft } from 'inviteContest/hooks.ts';

type TimerUntilEndProps = {
    className?: string;
};

export const TimerUntilEnd: FC<TimerUntilEndProps> = ({ className }) => {
    const { t } = useTranslation();
    const timeLeft = useInviteContestTimeLeft();

    return (
        <ICCard className={`px-3 py-1.5 ${className}`}>
            <p className="text-2xs text-invite-contest-dark-blue">
                {t('invite-contest.event-ends-in')}:
            </p>
            <div className="flex items-center gap-1.5">
                <div className="bg-[radial-gradient(50%_50%_at_50%_50%,#D4FF00_0%,#00C2FF_100%)] rounded-full p-0.5">
                    <img src="/bigpump/watch.gif" alt="" className="w-3 h-3 rounded-full" />
                </div>
                <span
                    className="text-sm leading-none"
                    style={{ width: `${timeLeft.length - 3.5}ch` }}
                >
                    {timeLeft}
                </span>
            </div>
        </ICCard>
    );
};
