import { useTimer } from 'common/hooks/useTimer.ts';
import { END_DATE } from 'inviteContest/constants.ts';
import dayjs from 'dayjs';
import { pluralize, useTranslation } from 'i18n';

export const useInviteContestTimeLeft = () => {
    const { t } = useTranslation();

    const timeLeft = useTimer({ timestamp: END_DATE.getTime(), direction: 'left' });
    const dayjsTimeLeft = dayjs.duration(timeLeft);
    const daysLeft = dayjsTimeLeft.days();
    const daysText =
        daysLeft > 0
            ? `${daysLeft} ${pluralize(daysLeft, [t('days-1'), t('days-2'), t('days-3')])}, `
            : '';

    return `${daysText}${dayjsTimeLeft.format('HH:mm:ss')}`;
};
