import classNames from 'classnames';

type Props = {
    diff: number;
};

export const RatingUpBadge = ({ diff }: Props) => {
    if (!diff) {
        return null;
    }

    const isPositive = diff > 0;

    return (
        <div
            className={classNames('rounded-md text-sm p-1 font-bold', {
                ['text-[#5B8F28] bg-[#B6CFA84D]']: isPositive,
                ['text-[#D12200] bg-[#D26D724D]']: !isPositive,
            })}
        >
            {diff}
            {isPositive ? '↑' : '↓'}
        </div>
    );
};
