import { navigation } from 'common/utils/routeUtils.ts';
import { useTranslation } from 'i18n';

type Props = {
    iconOnly?: boolean;
};

export const InviteContestButton = ({ iconOnly }: Props) => {
    const { t } = useTranslation();
    return (
        <button
            className="bg-white border border-white shadow-[0px_0px_30px_0px_#FFFFFF] flex items-center gap-1.5 p-1"
            onClick={navigation.inviteContest}
        >
            <img src="/frens_icon_2.png" alt="" className="w-5 h-5" />
            {!iconOnly && (
                <span className="font-semibold leading-none">
                    {t('invite-contest.invite-button')}
                </span>
            )}
        </button>
    );
};
